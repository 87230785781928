.question-container {
    min-width: 42rem;
    max-width: 60rem;
    width: auto;
    margin-top: 5%;
    height: 100vh;
    padding-top: 5%;

    @media (max-width: 767px) {
        min-width: unset;
        width: 100%;
    }
}

.parent-progress-back {
    align-items: center;
}

.progress-back {
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 10%;
    width: 100%;
}

.progress-eligibility {
    text-align: -webkit-center;
}