@use "../../variables.scss" as variables;

.selectable-card {
    background-color: white;
    border-radius: variables.$border-radius-sm;
    font-size: variables.$h3-font-size;
    border: none;

    .selectable-radio {
        &:checked,
        &:not(:checked) {
            position: absolute;
            left: -9999px;
        }

        &:checked + .selectable-card,
        &:not(:checked) + .selectable-card {
            position: relative;
            padding: 16px 24px;
            width: 100%;
            cursor: pointer;
        }

        &:checked + .selectable-card:before,
        &:not(:checked) + .selectable-card:before,
        &:checked + .selectable-card:after,
        &:not(:checked) + .selectable-card:after {
            content: "";
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }

        &:not(:checked) + .selectable-card:after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
        }

        &:checked + .selectable-card:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        &:not(:checked) + .selectable-card {
            border: 2px solid variables.$primary-300;
        }

        &:checked + .selectable-card {
            border: 2px solid variables.$primary;
        }
    }
}
