.user-layout {
    .sidebar-container {
        @media (min-width: 991px) {
            width: 18%;
        }

        @media (min-width: 768px) {
            width: 20%;
        }
    }

    .main {
        width: 100%;
        min-height: 85vh;

        @media (min-width: 768px) {
            margin-left: 20%;
            width: 80%;
            min-height: calc(100vh - 80px);
        }
    }
}
