@use "../../variables.scss" as variables;

.slider {
    margin-bottom: 15px;

    .track {
        top: 0 !important;
        left: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
        height: 35px !important;
        border-radius: 2px;

        &-0 {
            background: transparent !important;
        }

        &-1 {
            background: linear-gradient(to right, white, #d99efc) !important;
        }
    }

    .thumb {
        cursor: pointer;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 13px solid variables.$primary;
        background: white;
        z-index: 100 !important;

        span {
            position: absolute;
            top: -32px;
            font-weight: 500;
        }
    }
}

.marks-container {
    height: 35px;
    margin-top: -15px;

    .step-mark {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

.round-button {
    height: 40px;
    width: 40px;
    border: none;
    background-color: light-gray;
    border-radius: 50%;
    margin: 5px;
}

.selected {
    color: white;
    background-color: #8754af !important;
}

.custom-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
