.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  z-index: 1055; //TODO: use bootstrap variable here
  overflow: auto;

  /* From https://css.glass */
  background: #00000040;
  box-shadow: 0 4px 30px #0000001a;

  .modal-overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
  }
}
