@use "../../variables.scss" as variables;

.invoice {
    position: relative;
    width: 21cm;
    height: 29.7cm;
    background: white;
    display: block;
    margin: 0 auto;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 2.5rem;
    border: variables.$primary 1px solid;
    border-top: variables.$primary 5px solid;

    .invoice-table {
        z-index: 10;
    }

    .watermark {
        position: absolute;
        color: #5f5f5f2d;
        top: 50%;
        right: 50%;
        transform: rotate(-20deg) translate(50%, 10px);
        font-size: 100px;
        font-weight: bolder;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        z-index: 1;
    }

    .footer {
        border-top: variables.$dark-3 1px solid;
        position: absolute;
        bottom: 3rem;
        width: calc(100% - 5rem);
        padding-top: 1rem;

        @media print {
            position: fixed;
        }
    }

    @media print {
        margin: 0 !important;
        border: none;
        border-top: variables.$primary 5px solid;
    }
}

@page {
    margin: 0;
    size: 21cm 29.7cm;
}
