@use "../../variables.scss" as variables;

.tool-tip {
    z-index: 10;

    .tool-tip-bubble {
        animation: pop-up 200ms ease-in-out;
        min-width: 3rem;
        max-width: 10rem;
        width: max-content;
        position: absolute;
        left: -22%;

        &::after {
            content: "";
            position: absolute;
        }

        &.tool-tip {
            &-top {
                bottom: 100%;
                padding-bottom: 7px;
                transform: translate(50%);

                &::after {
                    border-left: 9px solid transparent;
                    border-right: 9px solid transparent;
                    border-top: 7px solid variables.$dark-5;
                    bottom: 0;
                    left: 45%;
                }
            }

            &-bottom {
                top: 100%;
                padding-top: 9px;
                transform: translate(50%);

                &::after {
                    border-left: 9px solid transparent;
                    border-right: 9px solid transparent;
                    border-bottom: 9px solid #474747;
                    top: 0;
                    left: 45%;
                }
            }

            &-left {
                top: 0%;
                left: 0;
                margin-left: 2rem;
            }

            &-right {
                top: 0%;
                right: 0;
            }
        }

        .tool-tip-message {
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
            position: relative;
        }
    }
}

@keyframes pop-up {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0.25;
    }
    50% {
        opacity: 0.5;
    }
    75% {
        opacity: 0.75;
    }
    100% {
        opacity: 1;
    }
}
