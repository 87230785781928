@use "../variables.scss" as variables;

.full-screen-layout {
    &.onboarding-theme {
        background-color: variables.$secondary-100;
        background-image: url("../../public/images/onboarding-bg.png");
        background-repeat: no-repeat;
        background-position: top;
        background-size: 100%;
        width: 100%;
        overflow: auto;

        .form-control {
            background-color: #ffffff80;
            padding: 16px 24px;
            border-radius: variables.$border-radius-sm;
            border: 2px solid #ffffff80;
            font-size: variables.$h3-font-size;

            &-sm {
                padding: 4px 10px 4px 14px;
            }

            &:focus {
                box-shadow: 0px 4px 12px 0px #00000012;
                border: 2px solid #8754af;
            }

            &:disabled {
                background-color: #ffffff80;
                opacity: 0.3;
            }

            &.stripe {
                height: 55px;
            }
        }

        .btn {
            border-radius: variables.$border-radius-sm !important;
            padding: 16px 30px !important;
            font-size: variables.$p-font-size !important;
            font-weight: variables.$font-weight-semibold !important;

            &-primary {
                &:hover {
                    box-shadow: variables.$box-shadow-primary;
                }

                &.rounded {
                    border-radius: variables.$border-radius-lg !important;
                }
            }

            &-secondary {
                border: #d2dde6 1px solid !important;

                &:hover {
                    color: variables.$primary !important;
                    border: variables.$primary 1px solid !important;
                }
            }

            &-link {
                font-weight: variables.$font-weight-light !important;
            }

            &-social {
                width: 255px;
                border-radius: variables.$border-radius-xs !important;
                padding: 0.35rem;
                font-size: 14px !important;
                font-weight: variables.$font-weight-semibold !important;
                font-family: "Google Sans", arial, sans-serif !important;
            }
        }

        .dropdown {
            .default-select {
                min-width: 4rem;
                padding: 16px 24px;
                color: variables.$dark-3 !important;
                background-color: rgba(255, 255, 255, 0.5019607843) !important;
                border: 2px solid transparent !important;
                border-radius: variables.$border-radius-sm;
                font-weight: 100;
                font-size: 1.5rem;
                line-height: 1.5;
                transition: 0.3s;
            }
        }
    }

    .main-container {
        margin-top: 5rem;
        min-height: calc(100vh - 5rem);
    }

    &.full-width {
        .main-container {
            max-width: 90rem;
        }
    }
}
