@use "../../variables.scss" as variables;

.custom-nav {
    min-height: 80px;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.02);
    z-index: 1001;

    a {
        text-decoration: none !important;
    }

    .logo {
        width: 14rem;
        min-width: 100px;
        max-width: 250px;

        @media (max-width: 1450px) {
            width: 11rem;
        }

        @media (max-width: 1199px) {
            width: 8.5rem;
        }

        @media (max-width: 768px) {
            width: 6.5rem;
        }
    }

    .links {
        column-gap: 3.5rem;
        display: none;

        @media (max-width: 1199px) {
            column-gap: 2rem;
        }

        @media (max-width: 991px) {
            column-gap: 1.5rem;
        }

        @media (min-width: 930px) {
            display: flex;
        }

        @media (max-width: 575px) {
            display: none;
        }
    }

    .navbar-right {
        column-gap: 2rem;
        display: none;

        @media (max-width: 1199px) {
            column-gap: 1rem;
        }

        @media (min-width: 768px) {
            display: flex !important;
        }

        @media (max-width: 575px) {
            display: none;
        }
    }

    .book-appointment-btn {
        min-width: 14rem;

        @media (max-width: 575px) {
            min-width: 11rem;
        }
    }

    .top-nav-collapse {
        width: 1rem;
        height: 1rem;
        -webkit-mask: url("../../../public/images/vectors/down.svg") no-repeat
            center;
        mask: url("../../../public/images/vectors/down.svg") no-repeat center;
        -webkit-mask-size: contain;
        mask-size: contain;
        background: variables.$primary;
    }

    .menu-btn {
        padding: 0.5rem !important;
        display: flex;

        @media (min-width: 930px) {
            display: none !important;
        }

        @media (max-width: 575px) {
            display: none;
        }

        .menu-icon {
            width: 1.5rem;
            height: 1.5rem;

            -webkit-mask-size: contain;
            mask-size: contain;
            background-color: variables.$primary;
            transition: background-color 0.1ms ease-in-out;

            &.close {
                -webkit-mask: url("../../../public/images/vectors/close.svg")
                    no-repeat center;
                mask: url("../../../public/images/vectors/close.svg") no-repeat
                    center;
            }

            &.open {
                -webkit-mask: url("../../../public/images/vectors/dots.svg")
                    no-repeat center;
                mask: url("../../../public/images/vectors/dots.svg") no-repeat
                    center;
            }
        }

        &:hover {
            .menu-icon {
                background-color: white;
            }
        }
    }

    .side-menu {
        display: none;
        position: fixed;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
        right: 0;
        top: 0;
        box-shadow: -0.5rem 0rem 2rem #8888887a;
        font-size: 1rem !important;
        z-index: 100;
        overflow-y: auto;
        width: 35%;
        min-width: 17rem;

        @media (max-width: 929px) {
            display: block;
        }

        .menu-content {
            height: calc(100% - 5rem);
            min-height: 600px;
        }

        .menu-gap {
            row-gap: 0.5rem;
        }
    }

    .accordion {
        .accordion-item {
            background-color: transparent !important;
            border: none;
            padding: 0;

            .accordion-button {
                padding: 0 !important;
                box-shadow: none !important;
                background-color: transparent;
                color: variables.$primary;
                font-weight: variables.$font-weight-normal;
            }

            .accordion-body {
                padding: 0.5rem var(--bs-accordion-body-padding-y) 0
                    var(--bs-accordion-body-padding-y);
            }
        }
    }

    .user-dropdown {
        background: white;
        box-shadow: 0px 10px 15px #3742602c;
        border-radius: 6px;
        right: 1.5rem;
        top: 3.75rem;
        padding: 1rem;
        z-index: 500;
    }
}

.logo-nav {
    z-index: 100;
    height: 5rem;
    background: white;

    .logo {
        width: 9rem;
    }

    .nav-link {
        .link-underline {
            height: 2px;
        }

        .disabled,
        .complete {
            opacity: 0.5;
        }

        .active {
            opacity: 1;
        }
    }
}
