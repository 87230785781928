@use "../../variables.scss" as variables;

.phone-input {
    position: relative;

    .input-container {
        .form-control {
            background-color: variables.$secondary;
            padding: 8px 12px 7px 104px;
            border-radius: variables.$border-radius-xs;
            border: 1px solid #d2dde6;
            height: auto;
            font-size: 1rem !important;

            &::placeholder {
                font-weight: variables.$font-weight-light !important;
            }

            &:-ms-input-placeholder {
                font-weight: variables.$font-weight-light !important;
            }

            &::-ms-input-placeholder {
                font-weight: variables.$font-weight-light !important;
            }

            &:disabled {
                background-color: #e9ecef !important;
                cursor: default;
            }
        }
    }

    .react-tel-input .form-control.invalid-number:focus{
        background-color: inherit !important;
        border: 1px solid #d2dde6 !important;
    }
    
    .prefix {
        left: 1px;
        top: 1px;
        height: calc(100% - 2px);
        border-top-left-radius: variables.$border-radius-xs;
        border-bottom-left-radius: variables.$border-radius-xs;
        border-right: 1px solid #d2dde6;
        background-color: #f0f0f0;
        font-size: 1rem;

        img {
            width: 2.5rem;
        }

        .code {
            margin-top: 7px;
        }
    }

    .flag-dropdown {
        display: none;
    }
}
