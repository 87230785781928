@use "../../variables.scss" as variables;

.order-card {
  .description-section {
    @media (min-width: 1200px) {
      min-width: 20rem;
    }
  }

  .description-section-rejected {
    @media (min-width: 1200px) {
      width: 59%;
    }
  }

  .divide-line {
    border-left: 1px solid variables.$dark-3;
    height: 57px;
    width: 1px;
  }

  .action-section {
    width: 100%;

    @media (min-width: 1200px) {
      width: 20%;
    }

    .view-detail-action {
      a {
        color: variables.$brown-black;
      }
    }
  }
}
