// default colors
$primary: #550385;
$primary-100: #875ea4;
$primary-200: #2a0043;
$primary-300: #875ea433;
$primary-400: linear-gradient(0deg, #5503850f, #5503850f),
    linear-gradient(0deg, #875ea40f, #875ea40f);
$primary-500: #5503851A;

$secondary: #f8f9fb;
$secondary-100: #dbcfed;
$secondary-200: #f6f9fb;

$dark-1: #36516a;
$dark-2: #647b90;
$dark-3: #706d6f;
$dark-4: #706d6f;
$dark-5: #464646;

$white: #ffffff;
$black: #000000;
$success: #199e41;
$danger: #d95757;
$red: #ff0000;

$gray: #eaeaea;
$light-gray: #faf7fb;
$light-blue: #b3cbff99; 
$medium-gray: #4e4a4c;
$brown-black: #231f20;
$dark-red: #a72f2f;
$dark-blue: #4d85ff;
$pink: #d99efc;
$fb: #1877F2;
$gray-card: #fcfcfc;

$font-family-base: "Titillium Web";
$theme-colors: () !default;

$theme-colors: map-merge(
    (
        "primary": $primary,
        "primary-100": $primary-100,
        "primary-200": $primary-200,
        "primary-500": $primary-500,
        "secondary": $secondary,
        "secondary-100": $secondary-100,
        "dark-1": $dark-1,
        "dark-2": $dark-2,
        "dark-3": $dark-3,
        "dark-4": $dark-4,
        "dark-5": $dark-5,
        "white": $white,
        "black": $black,
        "gray": $gray,
        "gray-card": $gray-card,
        "light-gray": $light-gray,
        "light-blue": $light-blue,
        "brown-black": $brown-black,
        "success": $success,
        "danger": $danger,
        "medium-gray": $medium-gray,
        "dark-red": $dark-red,
        "dark-blue": $dark-blue,
        "pink": $pink,
        "red": $red,
        "fb": $fb,
    ),
    $theme-colors
);

// font weight
$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// badges
$badge-border-radius: 4px;
$badge-padding-y: 0.5rem;
$badge-font-size: 0.75rem;

// font sizes
$h1-font-size: 3.5rem;
$h2-font-size: 2.5rem;
$h3-font-size: 1.5rem;
$p-font-size: 1.25rem;
$p1-font-size: 1.125rem;
$p2-font-size: 1rem;
$p3-font-size: 0.875rem;
$p4-font-size: 0.75rem;

// shadows
$box-shadow-primary: 0px 12px 36px 0px #9450bce5;
$box-shadow-sm: 0px 2px 8px rgba(0, 0, 0, 0.08);
$box-shadow-lg: 0px 8px 20px rgba(0, 0, 0, 0.16);

// border-radius
$border-radius-xs: 4px;
$border-radius-sm: 8px;
$border-radius-md: 24px;
$border-radius-lg: 44px;

// Maps ------------------------------------------------------------
$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer * 0.75,
    4: $spacer,
    5: $spacer * 1.5,
    6: $spacer * 2,
    7: $spacer * 2.5,
    8: $spacer * 3,
    9: $spacer * 3.5,
    10: $spacer * 4,
    11: $spacer * 4.5,
    12: $spacer * 5,
    content: $spacer * 12,
);

$grid-breakpoints: (
    xxs: 0,
    xs: 540px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$btn-focus-box-shadow: unset;
$input-focus-box-shadow: unset;
