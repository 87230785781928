//@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;600&display=swap");
@import "variables";
@import "bootstrap/scss/bootstrap";
@import "utilities";

body {
    font-size: $p3-font-size;
}

::placeholder {
    color: $dark-3 !important;
    font-weight: 100;
}

:-ms-input-placeholder {
    color: $dark-3 !important;
    font-weight: 100;
}

::-ms-input-placeholder {
    color: $dark-3 !important;
    font-weight: 100;
}

.btn-dark-transparent {
    background-color: #00000033;
    color: $white !important;

    &:hover {
        background-color: #00000066;
    }
}

.btn-white {
    box-shadow: 0px 1px 4px 0px #33025024;
    color: $primary;

    &:hover {
        color: $white;
        background: $primary;
        border-color: $primary;
    }
}

.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

.w-fit {
    width: fit-content;
}

.w-max {
    width: max-content;
}

.max-vw-25 {
    max-width: 25vw;
}

.h-fit {
    height: fit-content;
}

.h-max {
    height: max-content;
}

.z-10 {
    z-index: 10;
}

.z-100 {
    z-index: 100;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

/* 
  Borders
*/
.border {
    &-dark {
        border: 2px solid rgba(56, 56, 56, 0.4) !important;
    }

    &-primary {
        border: 2px solid rgb(231, 220, 238) !important;

        &-1 {
            border: 2px solid $primary !important;
        }
    }

    &-danger {
        border: 2px solid rgba(217, 87, 87, 0.4) !important;
    }

    &-light {
        border: 1px solid #bab3bf !important;
    }
}

/*
   * Navbar
   */

.navbar-brand {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .navbar-toggler {
    top: 0.25rem;
    right: 1rem;
}

.navbar .form-control {
    padding: 0.75rem 1rem;
    border-width: 0;
    border-radius: 0;
}

.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

/*
   * Forms
   */

.form-control {
    background-color: $secondary;
    padding: 8px 12px 8px 16px;
    border-radius: $border-radius-xs;
    border: 1px solid #d2dde6;

    &-sm {
        padding: 4px 10px 4px 14px;
    }
}

.form-label {
    &.disabled {
        opacity: 0.3;
    }
}

.form-check-input:disabled {
    opacity: 1;

    & ~ .form-check-label {
        opacity: 1;
    }
}

.form-switch {
    .form-check-input {
        height: 1rem;
    }
}

.form-check {
    display: flex;

    .form-check-input {
        min-width: 1em;
        min-height: 1em;
    }

    .form-check-label {
        margin-left: 0.5rem;
    }
}

.check-lg {
    &.form-check {
        .form-check-input {
            min-width: 1.5rem;
            height: 1.5rem;
            border-radius: unset;
            background-image: url("../public/images/vectors/tick-white.svg");
            background-size: 14px;
        }
    }
}

.custom-control-input:disabled {
    & ~ .custom-control-label {
        cursor: default !important;
    }
}

/*
   * Text area
   */

textarea {
    resize: none;
}

/*
   * Radio
   */

.custom-control {
    &.custom-radio {
        .custom-control-input {
            &:checked,
            &:not(:checked) {
                position: absolute;
                left: -9999px;
            }

            &:checked + .custom-control-label,
            &:not(:checked) + .custom-control-label {
                position: relative;
                padding-left: 28px;
                cursor: pointer;
            }

            &:checked + .custom-control-label:before,
            &:not(:checked) + .custom-control-label:before {
                content: "";
                position: absolute;
                left: 0;
                top: 1px;
                width: 18px;
                height: 18px;
                border: 1px solid #d2dde6;
                border-radius: 100%;
                background: #f0f0f0;
            }

            &:checked + .custom-control-label:after,
            &:not(:checked) + .custom-control-label:after {
                content: "";
                width: 12px;
                height: 12px;
                background: $primary;
                position: absolute;
                top: 4px;
                left: 3px;
                border-radius: 100%;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }

            &:not(:checked) + .custom-control-label:after {
                opacity: 0;
                -webkit-transform: scale(0);
                transform: scale(0);
            }

            &:checked + .custom-control-label:after {
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
            }
        }
    }
}

/*
   * Underline
   */

.underline {
    &::after {
        content: "";
        height: 2px;
        width: 60%;
        background: $primary;
        position: absolute;
        left: 0;
        bottom: -2rem;
    }

    &.fit {
        &-1 {
            &::after {
                bottom: 0;
            }
        }

        &-2 {
            &::after {
                bottom: -1rem;
            }
        }
    }

    &.center {
        &::after {
            left: 50%;
            transform: translate(-50%);
        }
    }

    &.short {
        &::after {
            width: 20%;
        }
    }
}

/*
   * Body Text
   */

.body {
    font-weight: $font-weight-light;
    font-size: $p-font-size;
    color: #313131;

    &-primary {
        color: #8e7f98;
    }

    &-small {
        font-size: $p2-font-size;
    }
}

/*
   * Headliner
   */

.headliner {
    font-weight: $font-weight-normal;
    font-size: $p4-font-size;
    letter-spacing: 0.12rem;
    text-transform: uppercase;
    color: #bcbec0;
}

/*
   * Scroll bar
   */

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background: #f4f6f9;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: 2px;
    transition: 0.3s;
}

::-webkit-scrollbar-thumb:hover {
    background: #550385ee;
}

.line-height-sm {
    line-height: 2em;
}

/*
   * Fonts
   */

.p1 {
    font-size: $p1-font-size;
}

.p2 {
    font-size: $p2-font-size;
}

.p3 {
    font-size: $p3-font-size;
}

.bg-light-gray {
    background-color: $light-gray;
}

.bg-primary-light {
    background-color: #f1ebf5;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default !important;
}

/*
    * Invoice
    */

.invoice-options {
    .custom-position {
        width: max-content !important;
        right: 0;
    }
}

/*
    * Message thread
    */

.message-thread-container {
    max-height: 20rem;
}

/*
    * Info card
    */

.info-card {
    max-width: 47rem;
    background: #5503850f;
    border-radius: $border-radius-sm;
}

/*
    * Spinner
    */

.spinner {
    -webkit-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

/*
    * Horizontal line with text
    */

.hr-text {
    line-height: 1rem;
    position: relative;
    outline: 0;
    border: 0;
    color: $primary-200;
    text-align: center;
    height: 1.5rem;
    opacity: 1;

    &:before {
        content: "";
        background: linear-gradient(
            to right,
            transparent,
            #8e7f9885,
            transparent
        );
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
    }

    &:after {
        content: attr(data-content);
        position: relative;
        display: inline-block;
        padding: 0 1.5rem;
        line-height: 1.5rem;
        color: $primary-200;
        background-color: $secondary-100;
    }
}
