.date-time-select {
    .date-picker-container {
        border-bottom: 1px solid #e9eef3;

        @media (min-width: 768px) {
            border-right: 1px solid #e9eef3;
            border-bottom: none;
        }
    }

    .available-slots {
        max-height: calc(100vh - 35rem);
        min-height: 8rem;
        overflow-y: auto;

        .slot {
            min-width: 15rem;
            box-shadow: 0px 1px 4px 0px #33025024;
        }
    }
}
