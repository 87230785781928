@use "../../variables.scss" as variables;

.card-input-container {
    .form-control-sm {
        border-color: transparent;

        &.focus {
            color: #8e7f98;
            background-color: #ffffff;
            border-color: variables.$primary;
            box-shadow: 0px 4px 12px 0px #00000012;
        }

        &.disabled {
            background-color: #e9ecef !important;
        }

        &::placeholder {
            color: #8e7f98 !important;
        }
    }

    .card-number-input {
        position: relative;

        .card-logo {
            height: 2.625rem;
            width: 3.75rem;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0%, -50%);
            display: flex;

            img {
                width: 2rem;
                margin: auto;
            }
        }
    }
}
