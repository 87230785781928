@use "../variables.scss" as variables;

.onboarding-layout {
    background-color: variables.$secondary-100;
    background-image: url("../../public/images/onboarding-bg.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
    width: 100%;
    overflow: auto;

    .btn {
        border-radius: variables.$border-radius-sm !important;
        padding: 16px 30px !important;
        font-size: variables.$p-font-size !important;
        font-weight: variables.$font-weight-semibold !important;

        &-primary {
            &:hover {
                box-shadow: variables.$box-shadow-primary;
            }

            &.rounded {
                border-radius: variables.$border-radius-lg !important;
            }
        }

        &-secondary {
            border: #d2dde6 1px solid !important;

            &:hover {
                color: variables.$primary !important;
                border: variables.$primary 1px solid !important;
            }
        }

        &-link {
            font-weight: variables.$font-weight-light !important;
        }
    }

    textarea {
        &.form-control {
            max-height: unset;
        }
    }

    .form-control {
        background-color: #ffffff80;
        padding: 16px 24px;
        border-radius: variables.$border-radius-sm;
        border: 2px solid #ffffff80;
        font-size: variables.$h3-font-size;
        max-height: 4.25rem;

        &-sm {
            padding: 4px 10px 4px 14px;
        }

        &:focus {
            box-shadow: 0px 4px 12px 0px #00000012;
            border: 2px solid #8754af;
        }

        &:disabled {
            background-color: #ffffff80;
            opacity: 0.3;
        }

        &.stripe {
            height: 55px;
        }
    }

    .dropdown {
        .default-select {
            min-width: 4rem;
            padding: 16px 24px;
            color: variables.$dark-3 !important;
            background-color: rgba(255, 255, 255, 0.5019607843) !important;
            border: 2px solid transparent !important;
            border-radius: variables.$border-radius-sm;
            font-weight: 100;
            font-size: 1.5rem;
            line-height: 1.5;
            transition: 0.3s;
        }
    }

    .main-container {
        margin-top: 5rem;
        min-height: calc(100vh - 5rem);
        justify-content: center;
        align-items: center;
        overflow: scroll;
    }
}
