.sidebar {
  background: #fff;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: sticky;
  top: 0;
  overflow-y: auto;

  .nav-item {
    transition: 0.3s ease-in-out;
    text-align: left;
    border-radius: 5px;

    &.active {
      background: #faf7fb;
    }
    .nav-link {
      padding: 0;
      display: flex;
      align-items: center;

      img {
        max-width: 1rem;
      }
    }
  }
}
