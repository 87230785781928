@use "../../variables.scss" as variables;

.dropdown {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-height: 4.25rem;

    .input-container {
        position: relative;

        &.disabled {
            background-color: #e9ecef;
            opacity: 1;
        }

        .search-icon {
            position: absolute;
            top: 0.55rem;
            right: 0.55rem;
        }

        .default-select {
            min-width: 4rem;
            padding: 0.5rem 0.75rem;
            color: #a5b6cf !important;
            background-color: #f8f9fb !important;
            border: 1px solid #d2dde6 !important;
            border-radius: variables.$border-radius-xs;
            font-weight: variables.$font-weight-light;
            font-size: 1rem;
            line-height: 1.5;

            @media (max-width: 449px) {
                width: 9rem;
            }

            @media (max-width: 319px) {
                width: 8rem;
            }

            &.active {
                color: #212529 !important;
                font-weight: variables.$font-weight-normal;
            }

            &.disabled {
                background-color: #e9ecef !important;
                opacity: 1;
            }

            &.focus {
                box-shadow: 0px 4px 12px 0px #00000012;
                background: white !important;
                border: 2px solid #8754AF !important;
            }
        }

        .option-list {
            border-radius: variables.$border-radius-xs;
            position: absolute;
            background-color: variables.$white;
            box-shadow: variables.$box-shadow-sm;
            max-height: 250px;
            overflow-y: auto;
            z-index: 1000;

            &.custom-position {
                width: max-content;
                min-width: 100% !important;
                text-align: start;
                min-width: 100px;

                @media (max-width: 675px) {
                    max-width: 25rem;
                }

                @media (max-width: 575px) {
                    width: 100%;

                    .fs-6 {
                        font-size: 14px !important;
                    }
                }
            }

            .option {
                cursor: pointer;
                padding: 0.25rem 0.5rem;
                transition: 0.3s;

                &:hover {
                    background-color: variables.$secondary-200;
                }
            }
        }
    }
}
