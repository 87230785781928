.selected-documents {
    box-shadow: 0px 4px 12px 0px #00000012;

    .file-name {
        max-width: 10rem;
    }

    .progress-container {
        max-width: 14rem;
        width: 100%;
    }

    .searchable-select {
        min-width: 9rem;
        width: max-content;

        @media (max-width: 479px) {
            min-width: 8rem;
        }
    }
}
