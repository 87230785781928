.landing-page {
    .subtitle,
    .name-field {
        min-width: 42rem;
        width: 32vw;

        @media (max-width: 767px) {
            min-width: unset;
            width: 100%;
        }
    }
    .text-field {
        margin-bottom: 10px;
    }
    .input-field {
        margin-bottom: 10px;
    }
    .a-within-p {
        margin: 0 5px;
        text-decoration: none;
        font-weight: bold;
    }
    .form-check-input {
        position: absolute;
        margin-top: 5px !important;
    }
    .consent-label {
        font-size: 15px;
    }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.condition-exit-page {
    max-width: 60rem;
}

.eligibility-exit-page-layout,
.age-exit-page,
.diagnosis-exit-page {
    max-width: 55rem;
}

.eligibility-success-page {
    max-width: 75rem;

    .subtext {
        max-width: 47rem;
    }

    .button-container {
        max-width: 48rem;
    }
}

.eligibility-success-icon {
    width: 48px;
    height: 48px;
    margin-left: 10px !important;
    margin-right: auto !important;
}

.back-parent-level1 {
    align-items: center;
    width: 75%;
    margin-left: 8% !important;
}

.back-parent-level2 {
    padding-left: 15%;
}

.eligibility-diagnosis-back {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-upload-container {
    display: flex;
}

.profile-upload-instructions {
    width: 40%;
}

.profile-upload-instructions-image {
    width: 60%;
}

.ageExit {
    top: 100px;
    position: absolute;
}

@media screen and (max-width: 768px) {
    .profile-upload-container {
        display: block;
    }
    .profile-upload-instructions {
        width: 100%;
    }

    .profile-upload-instructions-image {
        width: 100%;
    }
}