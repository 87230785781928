.basic-table-section {
    .bg-alternative-column {
        background-color: rgba(153, 240, 193, 0.123);
    }

    .price-col {
        width: 4rem;
    }
}

@media (min-width: 769px) {
    .basic-table-section {
        margin:auto -130px;
    }
}

@media (max-width: 769px) {
    .sm-text-nowrap {
        white-space: nowrap;
    }
}
