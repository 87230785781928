@use "../../../variables.scss" as variables;

.user-details-page {
    max-width: 60rem;
    width: 100%;

    .surgery-card-container {
        max-height: 15rem;
        background: #00000033;
        border-radius: 0 0 0.5rem 0.5rem;
        z-index: 1000;

        .surgery-card-list {
            max-height: 14rem;
            overflow: auto;
        }
    }
}

.select-slot-page {
    .divider {
        border-right: 1px solid #2a00431a;

        @media (max-width: 575px) {
            border: unset;
        }
    }

    .time-slot-card {
        width: 262.25px;
        height: 259.81px;
        border-radius: variables.$border-radius-sm;

        .btn-white {
            border: 1px solid #bab3bf;
            box-shadow: none;

            &:hover {
                border: 1px solid #8754af;
            }
        }
    }
}

.confirm-details-page {
    max-width: 42rem;

    img {
        max-width: 3.5rem;
    }

    .alert-text {
        color: rgb(210, 47, 39);;
    }
}

.checkout-page {
    height: 100vh !important;
    .container {
        &-left {
            width: 55%;
            height: calc(100vh - 5rem);
            position: absolute;
            top: 0;
            left: 0;

            @media (max-width: 767px) {
                width: 100%;
                height: unset;
                position: relative;
            }
        }

        &-right {
            width: 45%;
            height: -webkit-fill-available;
            position: absolute;
            top: 0;
            right: 0;

            @media (max-width: 767px) {
                width: 100%;
                height: unset;
                position: relative;
            }
        }
    }
}

.upload-documents-page,
.additional-notes-page,
.referred-by-page {
    max-width: 60rem;
}

.appointment-success-page {
    max-width: 60rem;
}
