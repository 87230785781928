@use "../../variables.scss" as variables;

.custom-calendar {
    padding: 1rem 1.5rem;
    font-family: variables.$font-family-base;
    border-radius: variables.$border-radius-sm;
    border: none;

    .react-datepicker__header {
        background-color: white;
        border-bottom: unset;

        .react-datepicker__current-month {
            margin-bottom: 1rem;
            color: variables.$primary;
        }

        .react-datepicker__day-names {
            margin-bottom: -1rem;

            .react-datepicker__day-name {
                color: variables.$primary;
                font-weight: variables.$font-weight-normal;
                width: 1.5rem;
            }
        }
    }

    .react-datepicker__navigation {
        top: 20px;

        &.react-datepicker__navigation--next {
            right: 40px;
        }

        &.react-datepicker__navigation--previous {
            left: 40px;
        }

        .react-datepicker__navigation-icon {
            &::before {
                display: none;
            }

            &.react-datepicker__navigation-icon--next {
                background: url("../../../public/images/vectors/arrow-right-filled.svg")
                    no-repeat;
                width: 1.5rem;
                height: 1.5rem;
                border: none;
            }

            &.react-datepicker__navigation-icon--previous {
                background: url("../../../public/images/vectors/arrow-left-filled.svg")
                    no-repeat;
                width: 1.5rem;
                height: 1.5rem;
                border: none;
            }
        }
    }

    .react-datepicker__day {
        border-radius: 3px !important;
        margin: 0.05rem;
        color: #000000;
        font-weight: 500;

        &:hover {
            border-color: transparent;
            background-color: #f0f0f0 !important;
        }

        &:focus {
            outline: unset;
            outline-offset: unset;
        }

        &.react-datepicker__day--disabled {
            background: #e0e0e0;
            opacity: 0.3;
        }

        &.react-datepicker__day--selected {
            background: #550385 !important;
            color: #fff;
        }

        &.react-datepicker__day--today {
            background: unset;
        }
    }
}
