.side-drawer {
    position: fixed;
    top: 0;
    width: 80%;
    max-width: 320px;
    height: 100%;
    background-color: #fff;
    z-index: 499;
    transition: transform 0.3s ease-in-out;

    &.left {
        left: 0;
        transform: translateX(-100%);
    }

    &.right {
        right: 0;
    }

    &.open {
        transform: translateX(0);
    }

    &.close {
        transform: translateX(-100%);

        &.right {
            transform: translateX(100%);
        }
    }
}
