@use "../../variables.scss" as variables;

.bottom-navigation {
    min-height: 40px;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.02);
    z-index: 100;

    .nav-link {
        img {
            height: 18px;
        }
    }
}
