@use "../../variables.scss" as variables;

.progress {
    width: 100%;
    height: 8px !important;

    @media (max-width: 575px) {
        width: 75%;
    }

    @media (max-width: 512px) {
        width: 65%;
    }

    @media (max-width: 449px) {
        width: 55%;
    }

    @media (max-width: 300px) {
        width: 50%;
    }
}

// CONFIG

$PROGRESS_BG: #fff;

.vertical-progress-container {
    .vertical-progress {
        position: relative;
        padding: 0 0 0 2.25rem;
        margin: 2rem 0 0;
        list-style: none;
    }

    .progress-item {
        position: relative;
        min-height: 75px;
        counter-increment: list;
        padding-left: 0.5rem;

        &:before {
            content: "";
            position: absolute;
            left: -1.8rem;
            top: 1.5rem;
            height: calc(100% - 2rem);
            width: 1px;
            border-width: 1px;
            border-style: dashed;
            border-left-color: variables.$primary;
            opacity: 0.2;
            border-top-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: -2.25rem;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: transparent;
            color: #fff;
            font-weight: 400;
            font-size: 13px;
            line-height: 2rem;
            text-align: center;
            border-width: 1px;
            border-style: solid;
            opacity: 0.4;
            border-color: variables.$primary;
        }

        //Hide border for Last Step
        &:last-child {
            &:before {
                border: none;
            }
        }

        //Completed
        &.progress-item-completed {
            color: #fff;

            &:after {
                content: "";
                background: variables.$primary;
                border-color: variables.$primary;
                opacity: 1;
            }

            &:before {
                border-style: solid;
                opacity: 1;
            }
        }

        //Rejected
        &.progress-item-rejected {
            color: #fff;

            &:after {
                content: "";
                background: variables.$gray;
                border-color: variables.$gray;
                opacity: 1;
            }

            &:before {
                border-style: solid;
                opacity: 1;
            }
        }

        //Active
        &.progress-item-active {
            &:after {
                background: #fff;
                border-color: variables.$primary;
                opacity: 1;

                &:before {
                    border-style: dashed;
                    opacity: 1;
                }
                //color: $PROGRESS_BG;
            }
        }
    }

    .progress-title {
        padding: 0.25rem 0 0.5rem;
        margin: 0;
        font-size: 1.25rem;
        color: #231f20;
        font-weight: bold;
    }
}
