@media (max-width: 576px) {
    .gap-sm {
        gap: 16px;
        justify-content: flex-start;
    }
}

@media (min-width: 577px) {
    .gap-sm {
        justify-content: flex-end;
    }
}