.parent {
    display: flex;
    flex-direction: column;
    padding: 0 0 40px;
}

.image-parent {
    flex: 1 1 0;
    text-align: center;
}

.image {
    box-sizing: border-box;
    max-width: 175px;
    width: 100%;
}

.parent-text {
    flex: 1 1 0;
    margin: auto;
    padding: 20px 40px 0;
    text-align: center;
}

.heading {
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    line-height: 1.75rem;
    font-size: 1.375rem;
    letter-spacing: 0;
    font-weight: 400;
    color: rgb(32,33,36);
    margin-top: 0;
}

.child {
    font-family: Roboto,Arial,sans-serif;
    line-height: 1.5rem;
    font-size: 1rem;
    letter-spacing: .00625em;
    font-weight: 400;
    display: inline-block;
    margin: auto;
    padding-left: 15px;
    text-align: left;
}

.child-text {
    margin: 15px 0;
}

.lock {
    width: 20px;
    padding-bottom: 10px;
}

.header-text {
    border-radius: 25px !important;
    align-self: center;
}

@media screen and (min-width: 601px) and (min-height: 501px){

    .parent {
        padding-left: 35px;
        padding-right: 35px;
    }

    .parent-mobile {
        flex-direction: row;
        max-width: 725px;

        .image {
            max-width: 100%;
        }
    }

}