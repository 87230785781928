@use "../../variables.scss" as variables;

.chat-thread {
    height: 80%;

    @media (max-width: 991px) {
        max-width: 100%;
    }

    .chat-title {
        .avatar-container {
            max-width: 3rem;
        }
    }

    .thread {
        min-height: calc(100vh - 231px);
        overflow: auto;

        @media (max-width: 767px) {
            min-height: calc(100vh - 295px);
        }

        .start-text-container {
            isolation: isolate;

            .start-text {
                padding: 0 1rem;
            }
        }

        .user-avatar {
            max-width: 2rem;
            min-width: 1.5rem;
        }
    }

    .chat-footer {
        @media (max-width: 767px) {
            padding-bottom: 1.75rem;
        }
    }
}
