.password-conditions {
    .form-check {
        display: -webkit-inline-box;

        .form-check-input {
            width: 25px;
            height: 25px;
            margin-right: 0.5rem;

            &:not(:checked[type="checkbox"]) {
                background: transparent;
                background-image: url("../../../public/images/vectors/tick-disabled.svg");
                background-size: 100%;
                border: none;
            }

            &:checked[type="checkbox"] {
                background-image: url("../../../public/images/vectors/tick-white.svg");
                background-size: 14px;
            }
        }
    }

    .password-conditions {
        .form-check-input {
            border-radius: 50% !important;
        }
    }
}
