.supporting-documents-container {
    .supporting-document {
        width: 100%;
    }

    &.files-1 {
        .supporting-document {
            max-width: calc(100% / 1);
        }
    }

    &.files-2 {
        .supporting-document {
            max-width: calc(100% / 2);
        }
    }

    &.files-3 {
        .supporting-document {
            max-width: calc(100% / 3);
        }
    }

    &.files-4 {
        .supporting-document {
            max-width: calc(100% / 4);
        }
    }

    &.files-5 {
        .supporting-document {
            max-width: calc(100% / 5);
        }
    }

    @media (max-width: 575px) {
        flex-wrap: wrap;

        .supporting-document {
            max-width: unset !important;
        }
    }
}
