@use "../../../variables.scss" as variables;

.create-account-page {
    .register-form {
        max-width: 40rem;

        .btn-social {
            width: 255px;
            border-radius: variables.$border-radius-xs !important;
            padding: 0.35rem;
            font-size: 14px !important;
            font-weight: variables.$font-weight-semibold !important;
            font-family: "Google Sans", arial, sans-serif !important;
        }
    }

    .get-started {
        max-width: 60rem;
    }

    .create-account-back {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.create-account-success-page {
    max-width: 75rem;
}

.back-parent {
    align-self: center;
}

