.order-action-section {
    .tool-tip {
        .tool-tip-bubble {
            left: -90%;
        }
    }
}

.order-error-section {
    background-color: rgba(217, 87, 87, 0.08);
}

.shipping-card,
.consultation-card {
    min-height: 10.25rem;

    @media (max-width: 575px) {
        min-height: auto;
    }

    .long {
        &.track-section {
            min-width: 15rem;
        }
    }
}
