.task-card {
    .pending-task-border {
        border: 2px solid rgba(85, 3, 133, 0.4);
    }

    .completed-task-background {
        background: linear-gradient(
            90deg,
            rgba(234, 237, 244, 1) 5%,
            rgba(247, 236, 255, 1) 20%,
            rgba(234, 235, 255, 1) 65%,
            rgba(230, 231, 249, 1) 80%
        );
    }
}
