.policy-container {
   .policy-table-container {
      min-width: 12rem;
   }

   li,
   p,
   h6 {
      letter-spacing: 0.75px;
      line-height: 1.5;
   }

   li,
   p {
      font-size: 15px;
   }

   li {
      margin-bottom: 0.5rem;
   }
}
