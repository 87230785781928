@use "../../variables.scss" as variables;

.dialog-box {
  width: fit-content;
  background: variables.$secondary;
  padding: 8px 12px 8px 12px;
  border: 1px solid #e9eef3;
  border-radius: 0 8px 8px 8px;
  max-width: calc(100% - 1rem);
  margin-right: 3rem;
  margin-left: 0;

  &.sent {
    border-radius: 8px 0 8px 8px;
    background: variables.$primary;
    color: variables.$white;
    margin-right: 0;
    margin-left: 3rem;
  }
}
