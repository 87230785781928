.avatar {
  width: 100%;
  height: 100%;
  position: relative;

  .avatar-inner {
    background-color: #d6d6d6;

    .avatar-img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      opacity: 1;
      transition: 0.5s ease;
      backface-visibility: hidden;
    }
  }
}
