@use "../../variables.scss" as variables;

.scaled-container {
    width: 400%;
    height: 400%;
    transform-origin: 0 0;
}

.card-outer {
    margin: 0 auto !important;
    margin-bottom: 4rem;
    border-radius: 25px;
    padding-bottom: 20px;

    .card-display {
        width: 860px;
        margin: 0 auto;
        box-shadow: variables.$box-shadow-primary;

        .round-br {
            border-radius: 0 0 40px 0;
            padding: 0 36px;
        }

        .title-col {
            padding: 20px 0 0 40px;

            h3 {
                font-size: 28px;
            }

            p {
                font-size: 20px;
            }
        }

        .flag-col {
            display: flex;
            justify-content: right;
            align-items: center;
        }

        .flag-img {
            height: 50px;
            float: right;
        }

        .id-image {
            width: 120px;
            border: 1px solid variables.$primary;
            border-radius: 5px;
        }

        .text-justify {

            text-align: justify;
        }

        .declaration-text {
            padding: 0 40px;
        }

        .qr-code-col {
            padding-right: 40px !important;

            p {
                font-weight: bold;
            }
        }
    }

    .info-area {
        .text-panel {
            p {
                font-size: 16px;
                margin-bottom: 5px;
            }
        }
    }
}

hr {
    color: variables.$primary !important;
}

.highlight {
    color: variables.$white !important;
    background-color: rgba(variables.$primary, 0.75);
    padding: 5px 10px;
}

.profile-image {
    width: 100%;
}

.fp10-image {
    width: 100%;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-22 {
    font-size: 22px !important;
}

.pl-40 {
    padding-left: 40px !important;
}

.pr-40 {
    padding-right: 40px !important;
}

.text-capitalize {
    text-transform: capitalize;
}

.remove-anchor-styles {
    color: variables.$white !important;
    text-decoration: none;
}

.pin-input {
    font-size: 1.5rem;
    border: 1px solid variables.$primary;
    border-radius: 5px;
}

.text-right {
    text-align: right !important;
}

.ReactCollapse--collapse {
    transition: height 500ms;
}

.show-more-btn {
    color: variables.$black !important;
    background-color: variables.$gray !important;
}

@media (min-width: 769px) and (max-width: 1441px) {
    .scaled-container {
        scale: 1;
    }
}

@media (max-width: 769px) {
    .card-outer {
        width: 95%;
        margin-top: 0;
    }

    .scaled-container {
        scale: 0.35;
    }
}

@media (max-width: 991px) {
    .card-outer {
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
    }

    .highlight {
        padding: 10px 0;
    }
}

@media (max-width: 1024px) {
    .card-outer {
        width: 95%;
        margin-top: 0;
        margin-bottom: 0;
    }
}

@media only screen and (max-device-width: 480px) {
    .move-row-up {
        margin-top: -22rem !important;
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    .move-row-up {
        margin-top: -22rem !important;
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    .move-row-up {
        margin-top: -22rem !important;
    }
}

@media only screen and (width: 820px) and (height: 1180px) {
    .scaled-container {
        scale: 0.5;
    }

    .move-row-up {
        margin-top: -18rem !important;
    }
}

@media only screen and (width: 1024px) and (height: 1366px) {
    .scaled-container {
        scale: 0.5;
    }

    .move-row-up {
        margin-top: -18rem !important;
    }
}

@media only screen and (width: 1024px) and (height: 858px) {
    .scaled-container {
        scale: 0.5;
    }

    .move-row-up {
        margin-top: -18rem !important;
    }
}

@media only screen and (width: 912px) and (height: 1368px) {
    .scaled-container {
        scale: 0.5;
    }

    .move-row-up {
        margin-top: -18rem !important;
    }
}