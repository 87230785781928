.text-field {
    .input-container {
        .eye {
            border: none;
            background-color: transparent;
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translate(0, -50%);
        }

        input {
            &.hide-arrows {
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                &[type="number"] {
                    -moz-appearance: textfield;
                }
            }
        }
    }
}
