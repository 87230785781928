@use "../../variables.scss" as variables;

.collapsible {
    &.custom-shadow {
        border: 1px solid #eae8ec;
        box-shadow: 0px 4px 4px 0px #0000001f;
        border-radius: variables.$border-radius-sm;
    }

    .text-truncate {
        display: inline-block;

        @media (max-width: 991px) {
            max-width: calc(100vw - 30rem);
        }

        @media (max-width: 767px) {
            &.long {
                max-width: calc(100vw - 10rem);
            }

            &.short {
                max-width: calc(100vw - 8rem);
            }
        }
    }
}
