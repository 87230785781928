.timer-window {
    height: calc(100vh - 250px);

    &.loading-only {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -60%);
    }

    &.short {
        height: auto;
    }

    .circle-timer {
        position: absolute;
        bottom: 55px;
        right: 55px;

        @media (max-width: 920px) {
            bottom: 15vh;
            right: calc(50% + 25px);
            transform: translate(50%, 50%) !important;
        }

        .circle {
            .mask,
            .fill {
                width: 50px;
                height: 50px;
                position: absolute;
                border-radius: 50%;
            }

            .mask {
                clip: rect(0px, 50px, 50px, 25px);

                &.full,
                .fill {
                    animation: fill 5s;
                }

                .fill {
                    clip: rect(0px, 25px, 50px, 0px);
                    background: #2d01471f;
                }
            }
        }
    }

    @keyframes fill {
        0% {
            transform: rotate(180deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
}
