@use "../../variables.scss" as variables;

.video-container {
    height: 75vh;

    @media (max-width: 767px) {
        height: calc(100vh - 152px);
    }

    .video-stream-container {
        .video-stream {
            width: 100%;
            height: calc(100vh - 92px);

            @media (max-width: 991px) {
                height: 100%;
            }

            @media (max-width: 767px) {
                height: calc(100vh - 152px);
            }

            &.publisher {
                &.show-overlay {
                    background-color: rgba(0, 0, 0, 0.5);
                    z-index: 2;
                    overflow: hidden;

                    .overlay-text {
                        position: absolute;
                        width: 100%;
                        color: white;
                        top: 25%;
                        transform: translate(0, 60%);
                        -ms-transform: translate(0, 35%);

                        @media (max-width: 767px) {
                            transform: translate(0, 30%);
                            -ms-transform: translate(0, 30%);
                        }

                        @media (max-width: 575px) {
                            transform: translate(0, 20%) scale(0.85);
                            -ms-transform: translate(0, 20%) scale(0.85);
                        }

                        @media (max-width: 410px) {
                            transform: translate(0, 5%) scale(0.65);
                            -ms-transform: translate(0, 5%) scale(0.65);

                            p {
                                display: none;
                            }
                        }
                    }
                }
            }

            .OT_publisher {
                border: 3px solid variables.$white;
                border-radius: calc(variables.$border-radius-xs * 2);
                box-shadow: variables.$box-shadow-sm;
                overflow: hidden;
                position: absolute;
                bottom: 1rem;
                right: 1rem;
                z-index: 10;
            }

            &.subscriber {
                .mute-btn-container {
                    z-index: 1;
                }
            }

            .OT_subscriber {
                position: absolute;
                top: 0;
                border-radius: calc(variables.$border-radius-xs * 2);
            }
        }

        .video-controls-container {
            bottom: 3rem;
            width: 100%;
            z-index: 2;
        }
    }
}

.chat-thread-container {
    .verify {
        &-success {
            border: 1px solid variables.$success;
            border-radius: variables.$border-radius-xs;
            color: variables.$success;
        }

        &-pending {
            border: 1px solid black;
            border-radius: variables.$border-radius-xs;
        }
    }
}

.browser-permission {
    margin-left: 40px;
    margin-right: 40px;
}
