@use "../../variables.scss" as variables;

.pill {
  padding: 12px 16px;
  width: fit-content;
  min-width: 3rem;
  text-transform: uppercase;
  font-size: 10px;

  &.default {
    background-color: rgba(179, 179, 179, 0.15);
    color: variables.$dark-1;
  }

  &.completed,
  &.delivered {
    background-color: rgba(25, 158, 65, 0.15);
    color: variables.$success;
  }
  &.active {
    background-color: #00a1e61a;
    color: #009fe3;
  }
  &.rejected,
  .cancelled {
    background-color: rgba(217, 87, 87, 0.15);
    color: variables.$danger;
  }
  &.pending,
  &.booked {
    background-color: rgba(85, 3, 133, 0.15);
    color: variables.$primary;
  }
}
