@use "../../variables.scss" as variables;

.modal-dialog {
    background-color: variables.$white;
    border-radius: 8px;

    .modal-body {
        max-height: 40rem;
    }

    @media (max-width: 575px) {
        height: 100% !important;

        .modal-body {
            height: calc(100% - 214px);
            overflow: auto;
            flex: unset !important;
        }
    }

    .modal-header {
        max-height: 130px;

        .close-button {
            top: 1rem;
            right: 1rem;
            border: none;
        }
    }

    .modal-footer {
        border: 1px solid #e9eef3;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background: white;
        z-index: 100;

        @media (max-width: 575px) {
            position: fixed;
            bottom: 0;
            width: 100%;
        }
    }
}
