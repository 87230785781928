@use "../../variables.scss" as variables;

.btn {
    border-radius: variables.$border-radius-xs !important;
    padding: 16px 24px !important;
  
    &-primary {
      &:hover {
        box-shadow: variables.$box-shadow-primary;
      }
  
      &.rounded {
        border-radius: variables.$border-radius-lg !important;
      }
    }
  
    &-secondary {
      border: #d2dde6 1px solid !important;
  
      &:hover {
        color: variables.$primary !important;
        border: variables.$primary 1px solid !important;
      }
    }
  
    &-link {
      font-weight: variables.$font-weight-light !important;
    }
  }