@use "../../variables.scss" as variables;

.surgery-card {
    background: variables.$white;
    transition: 0.1s;
    border-radius: variables.$border-radius-sm;
    border: none;
    box-shadow: 0px 1px 4px 0px #33025024;
    cursor: pointer;
}
